import React from 'react';
const PDFViewer = ({ pdfPath }) => {
  return (
<div id="pdf">
    <object width="100%" height="700" type="application/pdf" data={`${pdfPath}#zoom=85&scrollbar=0&toolbar=0&navpanes=0`} id="pdf_content">
        <p>Error PDF cannot be displayed.</p>
    </object>
</div>
  );
};
export default PDFViewer;
