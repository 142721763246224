import React from 'react';
import { Card, CardContent, CardMedia, Typography, Avatar, Box, useMediaQuery } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

function ReviewCard({ review }) {
  const isLargeScreen = useMediaQuery('(min-width:600px)');

  return (
    <Card 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        maxWidth: isLargeScreen ? 600 : 345, 
        margin: 'auto', 
        backgroundColor: '#1b1b1b', 
        color: 'white' 
      }}
    >
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <Avatar sx={{ bgcolor: 'grey.800', marginRight: 2 }}>
            <PersonIcon />
          </Avatar>
          <Typography variant="h6" color="white">{review.reviewer}</Typography>
        </Box>
        <CardMedia
          component="img"
          image={review.image}
          alt={review.title}
        />
      </CardContent>
    </Card>
  );
}

export default ReviewCard;
