import {Box, TextField} from '@mui/material'
import React,{useState,useEffect} from 'react'
import {Button} from '@mui/material'
import axios, { Axios } from 'axios'
import { Quizz } from './Quizz';
import Quiz from 'react-quiz-component';
import './Courses.css'
import Typography from '@mui/material/Typography';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle,InputLabel,MenuItem,FormControl,Select,Input,InputAdornment,IconButton} from '@mui/material'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {ArrowBack,ArrowForward} from '@mui/icons-material';
import Localbase from 'localbase'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
export const Courses = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const[classes,setClasses]=useState([])
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const genRand = (len) => {
    return Math.random().toString(36).substring(2,len+2);
  }
  const[count,setCount]=useState([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121])
  const[counts,setCounts]=useState([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,123,124,125,126,127,128,129,130,131,132,133,134,135,136,137,138,139,140,141,142,143,144,145,146,147,148])
  //http://localhost:3000/courses
  const urlget='https://astucefx.herokuapp.com/ok/getusers'
  const [ID, setID]=useState('')
  const [users,setUsers]=useState([])
  const [userRN,setUserRN]=useState({referral:[]})
  const [userpdf,setUserpdf]=useState(false)

  const images=['last.png','last2.png','1.png','2.png','3.png','4.png','5.png','6.png','7.png','8.png','9.png','10.png']
  let AstuceFXUser = new Localbase('AstuceFXUser')
  
  const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
  function checker(arg){
    console.log(classes.user);
    const searchValue1 = 'beginner';
        const searchValue2 = 'intermediate';
        const searchValue3 = 'advance';
        
        
        classes.user.map((e)=>{
          for (const key in e) {
            if (e[key] === searchValue1) {
              //console.log(key); // Outputs "beginner"
              if(key==arg){
                setbeginner(true)
              }
            }
            if (e[key] === searchValue2) {
              //console.log(key); // Outputs "didyherve"
              if(key==arg){
                setintermediate(true)
              }
            }
            if (e[key] === searchValue3) {
              //console.log(key); // Outputs "didyherve"
              if(key==arg){
                setadvance(true)
              }
            }
          }
          
        })
  }
	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	const goToPrevPage = () =>
		setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

	const goToNextPage = () =>
		setPageNumber(
			pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
		);
    const[intermediate,setintermediate]=useState(false)
    const[beginner,setbeginner]=useState(false)
    const[advance,setadvance]=useState(false)
    const [num, setNum] = useState(0);
    const [Useremail,setUseremail]=useState('');
    const [Userpassword,setUserpassword]=useState('');
    const [err,setErr]=useState('');
    const [open, setOpen] = useState(false);
    const [openA, setOpenA] = useState(false);
    const [openZ, setOpenZ] = useState(false);
    const [openY, setOpenY] = useState(false);
    const [openX, setOpenX] = useState(false);
    const [openQuizz, setOpenQuizz] = useState(false);
    const [openB, setOpenB] = useState(false);
    const [openC, setOpenC] = useState(false);
    const [link, setLink] = useState("");
    const urlModifyBrowser=`https://astucefx.herokuapp.com/ok/update/${Useremail}`
    const [CourseAccess, setCourseAccess]=useState(0)
    
    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
      const handleCloseQuizz = () => {
        setOpenQuizz(false);
      };
      const handleClickOpenA = () => {
        setOpenA(true);
      };
      const handleClickOpenB= () => {
        setOpenB(true);
      };
      const handleClickOpenC= () => {
        setOpenC(true);
      };
    
      const handleCloseA = () => {
        setOpenA(false);
      };
      const handleCloseB = () => {
        setOpenB(false);
      };
      const handleCloseX = () => {
        setOpenX(false);
      };
      const handleCloseC = () => {
        setOpenC(false);
      };
      const handleCloseY = () => {
        setOpenY(false);
      };
      const [userInfo,setUserInfo]=useState({
        email:"",
        password:''

    })

    const handleClickZ = () => {
      setOpenZ(true);
    };
    const handleCloseZ = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenZ(false);
    };
    
    const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    
    const url='https://astucefx.herokuapp.com/ok/addpatient'
    const [doctors, setDoctors]=useState([
        {
            id: 1,
            name: "Full course Smart Money concept",
            Description: "full course smart money concept from market structure to confirmation entry"
        },
        {
            id: 2,
            name: "Scalping Mastery",
            Description: "completion of the full course smc added to new some advance concept how to scalp like a properly"
        },
        {
            id: 3,
            name: "Synthetic trading strategy",
            Description: "learn how to trade synthetic indices"
        },
        {
          id: 4,
          name: "Premium course",
          Description: "learn how to trade synthetic indices"
      }
    ])
    const [name, setName]=useState("")
    const [profession, setProfession]=useState("")
    const [bool, setBool]=useState(false)
    const [submitted, setSubmitted]=useState(false)

    // var h = window.innerHeight;
    // useEffect(()=>{
    //   console.log(navigator.platform)
    // },[])
    return (
        <div className='boxcourse'>
            <div style={{height:"50px"}}></div>
            {bool===false?<h2 style={{opacity:"0.7"}}>Choose Course you wanna access</h2>:submitted===false?<div><h2 style={{opacity:"0.7"}}>Course </h2><div>
            <Button onClick={()=>{
              if(CourseAccess==3){
                setCourseAccess(2)
              }else if(CourseAccess==2){
                setCourseAccess(1)
              }
            }} variant='contained' style={{marginRight:"10px"}}><ArrowBack/></Button>
            <Button onClick={()=>{
                  if(CourseAccess==1){
                    setCourseAccess(2)
                  }else if(CourseAccess==2){
                    setCourseAccess(3)
                  }
            }} variant='contained' style={{marginRight:"10px"}}><ArrowForward/></Button>
              </div>
              <div>
              <Button onClick={()=>{
                setBool(false)
                setErr('')
                setUseremail("")
                setUserpassword("")
            }} variant='contained' style={{margin:"10px"}}>Log out</Button>

            <div><Button onClick={()=>{
              setOpenQuizz(true)
            }} variant='contained'>Quizz</Button></div>
            <div>
            {userRN.referral.length==0?"":<div>
              <p>refer a friend and get 15% commission from mentorship fee</p>
              <h5>copy referral code <Button onClick={() => {
                handleClickZ()
                if (navigator.clipboard) {
                  navigator.clipboard.writeText(link).then(
                  function() {
                    console.log("Text copied to clipboard successfully!");
                  },
                  function(err) {
                    console.error("Failed to copy text: ", err);
                  }
                );
              } else {
                // Fallback code for unsupported browsers
              }}}><ContentCopyIcon/></Button></h5></div>}
              <div>

<Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={openZ} autoHideDuration={3000} onClose={handleCloseZ}>
        <Alert onClose={handleCloseZ} severity="success" sx={{ width: '100%' }}>
          copied successfully!
        </Alert>
      </Snackbar>


    </Stack>
    
    
                {userRN.referral.length!=0?"":<Button onClick={()=>{
                  const Becomereferral=`https://astucefx.herokuapp.com/ok/updatereferral/${userRN.email}`
                  axios.post(Becomereferral,{referral:["default"]})
                  .then(data=>{
                    console.log(data)
                    setOpenX(true)
                  })
                  .catch(err=>{
                    console.log(err);
                  })
                }}>Become An affiliate</Button>}
                {userRN.referral.length!=0?<Button onClick={()=>{
                      // console.log(userRN.referral);
                      setOpenY(true)
                }}>Check my referrals</Button>:""}
                    
              </div>

            </div>
              </div>
              </div>:""}
            {bool==true?<div>
                {CourseAccess==1?<div className='courseSMC'>

                    <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography><b>Content</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
                <li style={{textAlign:'left'}}><b>Market Struture</b></li>
                <li style={{textAlign:'left'}}><b>Orderblock</b></li>
                <li style={{textAlign:'left'}}><b>Liquidity</b></li>
                <li style={{textAlign:'left'}}><b>Daily range</b></li>
                <li style={{textAlign:'left'}}><b>Market Inefficiency</b></li>
                <li style={{textAlign:'left'}}><b>Powerfull Setups</b></li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography><b>Market Structure</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
            <li style={{textAlign:'left'}}><b>Part 1</b></li>
            <video width="100%" height="100%" controls poster="/smc/1.png" controlsList='nodownload'>
              <source src="https://astucefx.net/files/smc/marketStruture/orderblockpart1.mp4" type="video/mp4"/>
            </video>
            <li style={{textAlign:'left'}}><b>Part 2</b></li>
            <video width="100%" height="100%" controls poster="/smc/2.png" controlsList='nodownload'>
              <source src="https://astucefx.net/files/smc/marketStruture/marketstructurepart2.mp4" type="video/mp4"/>
            </video>
            <li style={{textAlign:'left'}}><b>Part 3</b></li>
            <video width="100%" height="100%" controls poster="/smc/3.png" controlsList='nodownload'>
              <source src="https://astucefx.net/files/smc/marketStruture/marketstructurepart3.mp4" type="video/mp4"/>
            </video>
            <li style={{textAlign:'left'}}><b>Part 4</b></li>
            <video width="100%" height="100%" controls poster="/smc/4.png" controlsList='nodownload'>
              <source src="https://astucefx.net/files/smc/marketStruture/marketstructurepart4.mp4" type="video/mp4"/>
            </video>

            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography><b>Orderblock</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
            <li style={{textAlign:'left'}}><b>Part 1</b></li>
            <video width="100%" height="100%" controls poster="/smc/ob1.png" controlsList='nodownload'>
              <source src="https://astucefx.net/files/smc/orderBlock/ob1.mp4" type="video/mp4"/>
            </video>
            <li style={{textAlign:'left'}}><b>Part 2</b></li>
            <video width="100%" height="100%" controls poster="/smc/ob2.png" controlsList='nodownload'>
              <source src="https://astucefx.net/files/smc/orderBlock/ob2.mp4" type="video/mp4"/>
            </video>

            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography><b>Liquidity</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <video width="100%" height="100%" controls poster="/smc/LQ.png" controlsList='nodownload'>
              <source src="https://astucefx.net/files/smc/liquidity/LQ.mp4" type="video/mp4"/>
            </video>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography><b>Daily range</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {/* <video width="100%" height="100%" controls poster="/smc/LQ.png" controlsList='nodownload'>
              <source src="https://astucefx.net/files/vid.mp4" type="video/mp4"/>
            </video> */}
            coming soon
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography><b>Market Inefficiency</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <video width="100%" height="100%" controls poster="/smc/market.png" controlsList='nodownload'>
              <source src="https://astucefx.net/files/smc/Market_inefficiency.mp4" type="video/mp4"/>
            </video>
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography><b>Powerfull Setups</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {/* <video width="100%" height="100%" controls poster="/smc/LQ.png" controlsList='nodownload'>
              <source src="https://astucefx.net/files/vid.mp4" type="video/mp4"/>
            </video> */}
            coming soon
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion disabled>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Bonus</Typography>
        </AccordionSummary>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography><b>How i backtest</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <video width="100%" height="100%" controls poster="/smc/howIbacktest.png" controlsList='nodownload'>
              <source src="https://astucefx.net/files/smc/howIbacktest.mp4" type="video/mp4"/>
            </video>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography><b>calculate lot size without any website on mt5</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <video width="100%" height="100%" controls poster="/smc/lotsize.png" controlsList='nodownload'>
              <source src="https://astucefx.net/files/smc/lotsize.mp4" type="video/mp4"/>
            </video>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography><b>Wyckoff Accumulation/distribution</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <video width="100%" height="100%" controls poster="/smc/LQ.png" controlsList='nodownload'>
              <source src="https://astucefx.net/files/smc/Wyckoff.mp4" type="video/mp4"/>
            </video>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography><b>How to take partial on MT5 and how to resize your stop loss and take profit</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <video width="100%" height="100%" controls poster="/smc/LQ.png" controlsList='nodownload'>
              <source src="https://astucefx.net/files/smc/partial.mp4" type="video/mp4"/>
            </video>
          </Typography>
        </AccordionDetails>
      </Accordion>

                </div>:""}
                {CourseAccess==2?<div className='courseSMC'>


                <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography><b>Scalping Mastery</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <video width="100%" height="100%" controls poster="/PINGPONG/scalping.png" controlsList='nodownload'>
              <source src="https://astucefx.net/files/pingpong/ScalpingGOAT.mp4" type="video/mp4"/>
            </video>
            <video width="100%" height="100%" controls poster="/PINGPONG/scalping.png" controlsList='nodownload'>
              <source src="https://astucefx.net/files/pingpong/pinpong2.mp4" type="video/mp4"/>
            </video>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography><b>PDF</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          {/* <div>
			

			<Document
				file="astucefxCourse.pdf"
				onLoadSuccess={onDocumentLoadSuccess}
			>
				<Page pageNumber={pageNumber} />
			</Document>
      <nav>
        <Button onClick={goToPrevPage} variant='contained' style={{marginRight:"10px"}}><ArrowBack/></Button>
        <Button onClick={goToNextPage} variant='contained' style={{marginRight:"10px"}}><ArrowForward/></Button>
				<p>
					Page {pageNumber} of {numPages}
				</p>
        
			</nav>
      
		</div> */}
    {
      userpdf==false?<div>you can't access the pdf</div>:counts.map(elt=><div>
        <p>page {elt}</p>
        <img style={{width:'100%',heigth:"100%"}} src={`/pdfs/${elt}.jpg`}/>
        </div>)
        
    }
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
  <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    aria-controls="panel2a-content"
    id="panel2a-header"
  >
    <Typography><b>PDF in Arabic</b></Typography>
  </AccordionSummary>
  <AccordionDetails>
    <Typography>
    {/* <div>


<Document
  file="astucefxCourse.pdf"
  onLoadSuccess={onDocumentLoadSuccess}
>
  <Page pageNumber={pageNumber} />
</Document>
<nav>
  <Button onClick={goToPrevPage} variant='contained' style={{marginRight:"10px"}}><ArrowBack/></Button>
  <Button onClick={goToNextPage} variant='contained' style={{marginRight:"10px"}}><ArrowForward/></Button>
  <p>
    Page {pageNumber} of {numPages}
  </p>
  
</nav>

</div> */}
{
      userpdf==false?<div>you can't access the pdf</div>:counts.map(elt=><div>
        <p>page {elt}</p>
        <img style={{width:'100%',heigth:"100%"}} src={`/pdfa/${elt}.jpg`}/>
        </div>)
        
    }
    </Typography>
  </AccordionDetails>
</Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography><b>PDF</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div>
			
      {/* {count.map(a=>{
        <img src={`/pdf/000${a}`}/>
      })} */}
      {count.map(elt=><div>
        <p>page {elt}</p>
        <img style={{width:'100%',heigth:"100%"}} src={`/pdf/${elt}.jpg`}/>
        </div>)}

      
		</div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography><b>confirmation entry| Top down analysis</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <video width="100%" height="100%" controls poster="/PINGPONG/conf.png" controlsList='nodownload'>
              <source src="https://astucefx.net/files/pingpong/confirmation.mp4" type="video/mp4"/>
            </video>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography><b>Live scalping mastery</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <video width="100%" height="100%" controls poster="/PINGPONG/live.png" controlsList='nodownload'>
              <source src="https://astucefx.net/files/pingpong/Livescalpingmastery.mp4" type="video/mp4"/>
            </video>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography><b>backtest Session</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <video width="100%" height="100%" controls poster="/PINGPONG/backtest.png" controlsList='nodownload'>
              <source src="https://astucefx.net/files/pingpong/Backtest.mp4" type="video/mp4"/>
            </video>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography><b>Setups</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {images.map(elt=><img style={{width:'100%',heigth:"100%"}} src={`/PINGPONG/${elt}`}/>)}
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography><b>Zoom calls recorded</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <ul>
            <li style={{textAlign:'left'}}><b>One to One zoom call /Osi_O_Scott</b></li>
            <video width="100%" height="100%" controls poster="/PINGPONG/aa.png" controlsList='nodownload'>
              <source src="https://astucefx.net/files/pingpong/Osi_O_Scott.mp4" type="video/mp4"/>
            </video>
            <li style={{textAlign:'left'}}><b>Zoom call 22/11/22</b></li>
            <video width="100%" height="100%" controls poster="/PINGPONG/aa.png" controlsList='nodownload'>
              <source src="https://astucefx.net/files/pingpong/Zoomcall22_11_22.mp4" type="video/mp4"/>
            </video>
            <li style={{textAlign:'left'}}><b>One to One zoom Strategy explained part 1</b></li>
            <video width="100%" height="100%" controls poster="/PINGPONG/aa.png" controlsList='nodownload'>
              <source src="https://astucefx.net/files/pingpong/OnetoOnezoomStrategyexplainedpart1.mp4" type="video/mp4"/>
            </video>
            <li style={{textAlign:'left'}}><b>One to One zoom Strategy explained part 2</b></li>
            <video width="100%" height="100%" controls poster="/PINGPONG/aa.png" controlsList='nodownload'>
              <source src="https://astucefx.net/files/pingpong/part2.mp4" type="video/mp4"/>
            </video>
            <li style={{textAlign:'left'}}><b>Weekly zoom call + how to get 5rr every morning</b></li>
            <video width="100%" height="100%" controls poster="/PINGPONG/aa.png" controlsList='nodownload'>
              <source src="https://astucefx.net/files/pingpong/5rreverymorning.mp4" type="video/mp4"/>
            </video>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      
                </div>:""}
                {CourseAccess==3?<div className='courseSMC'>

                  <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography><b>What are synthetic indices?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Synthetic indices are unique indices that mimic real-world market movement but with a twist — they are not affected by real-world events. These indices are based on a cryptographically secure random number generator, have constant volatility, and are free of market and liquidity, you can trade them 24 hour a day, 7 days a week, they never close and no spread time they move at any time
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography><b>Chart link</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          
            <a target='_blank' href="https://tradingview.binary.com/" >click here</a>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography><b>synthetics trading with Smc</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <video width="100%" height="100%" controls poster="/synthetics/synthetics.png" controlsList='nodownload'>
              <source src="https://astucefx.net/files/synthetics/synthetics.mp4" type="video/mp4"/>
            </video>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography><b>create an account on Deriv real/demo</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          
            <a target='_blank' href="https://app.deriv.com/" >click here</a>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography><b> lot sizes on synthetics</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          
          <img style={{width:'100%',heigth:"100%"}} src='/synthetics/1.png'/>
          <img style={{width:'100%',heigth:"100%"}} src='/synthetics/2.png'/>
          </Typography>
        </AccordionDetails>
      </Accordion>


                </div>:""}

                {CourseAccess==4?<div className='courseSMC'>


                <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography><b>Beginners</b></Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                  {beginner!=false?
                  classes.beginner.map((elt) => (
                      <div key={elt}>
                        <video width="100%" height="100%" controls poster="" controlsList='nodownload'>
                          <source src={`https://astucefx.net/files/Beginner/${elt}`} type="video/mp4"/>
                        </video>
                      </div>
                    )):<div>you cannot access the Beginners premium course</div>}
                {/* <video width="100%" height="100%" controls poster="/synthetics/synthetics.png" controlsList='nodownload'>
                <source src="https://astucefx.net/files/synthetics/synthetics.mp4" type="video/mp4"/>
                </video> */}
                </Typography>
                </AccordionDetails>
                </Accordion>

                <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography><b>intermediate</b></Typography>
                </AccordionSummary>
                <Typography>
                {intermediate!=false?
                    classes.intermediate.map((elt) => (
                      <div key={elt}>
                        <video width="100%" height="100%" controls poster="/synthetics/synthetics.png" controlsList='nodownload'>
                          <source src={`https://astucefx.net/files/Intermediate/${elt}`} type="video/mp4"/>
                        </video>
                      </div>
                    ))
                    :<div>you cannot access the advance premium course</div>}
                </Typography>
                </Accordion>
                <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography><b>advance</b></Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                {advance!=false?
                    classes.advance.map((elt) => (
                      <div key={elt}>
                        <video width="100%" height="100%" controls poster="" controlsList='nodownload'>
                          <source src={`https://astucefx.net/files/Advance/${elt}`} type="video/mp4"/>
                        </video>
                      </div>
                    ))
                    :<div>you cannot access the advance premium course</div>}
                </Typography>
                </AccordionDetails>
                </Accordion>


                </div>:""}
                  


                {CourseAccess==3?<div className='courseSMC'>

                  <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography><b>What are synthetic indices?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Synthetic indices are unique indices that mimic real-world market movement but with a twist — they are not affected by real-world events. These indices are based on a cryptographically secure random number generator, have constant volatility, and are free of market and liquidity, you can trade them 24 hour a day, 7 days a week, they never close and no spread time they move at any time
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography><b>Chart link</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          
            <a target='_blank' href="https://tradingview.binary.com/" >click here</a>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography><b>synthetics trading with Smc</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <video width="100%" height="100%" controls poster="/synthetics/synthetics.png" controlsList='nodownload'>
              <source src="https://astucefx.net/files/synthetics/synthetics.mp4" type="video/mp4"/>
            </video>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography><b>create an account on Deriv real/demo</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          
            <a target='_blank' href="https://app.deriv.com/" >click here</a>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography><b> lot sizes on synthetics</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          
          <img style={{width:'100%',heigth:"100%"}} src='/synthetics/1.png'/>
          <img style={{width:'100%',heigth:"100%"}} src='/synthetics/2.png'/>
          </Typography>
        </AccordionDetails>
      </Accordion>


                </div>:""}
                
            </div>:

            <div className="items">

                {doctors.map(elt=>
                    <div className='item'>
                        <img alt='#' style={{width:"100%", height:"100%",  borderTopLeftRadius:"10px", borderBottomLeftRadius:"10px"}} src='/AFX-Best.jpg'/>
                        <div className='courses'>
                            <h3 className='titlecourse'>{elt.name}</h3>
                            
                        </div>
                        <button onClick={()=>{
                            setName(elt.name)
                            setProfession(elt.Description)
                            // setBool(!bool)
                            handleClickOpen()
                            setCourseAccess(elt.id)
                            axios.get(urlget)
                            .then(data=>{
                              setUsers(data.data.msg)
                              
                            })
                            .catch(err=>{
                              console.log(err)
                            })
                        }} className='btn btn-outline-success'>Access</button>
                    </div>
                    )}
                </div>}
                    
                
               





                <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                
            >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <p id="heading">Login</p>
          <TextField  value={Useremail} onChange={(e)=>{setUseremail(e.target.value)}} error={err=='err'?true:""} helperText={userInfo.name==''?"enter your name":""}  className='textinput' label="Email" variant="standard" />
          {/* <div class="field">
    <svg class="input-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
    <path d="M13.106 7.222c0-2.967-2.249-5.032-5.482-5.032-3.35 0-5.646 2.318-5.646 5.702 0 3.493 2.235 5.708 5.762 5.708.862 0 1.689-.123 2.304-.335v-.862c-.43.199-1.354.328-2.29.328-2.926 0-4.813-1.88-4.813-4.798 0-2.844 1.921-4.881 4.594-4.881 2.735 0 4.608 1.688 4.608 4.156 0 1.682-.554 2.769-1.416 2.769-.492 0-.772-.28-.772-.76V5.206H8.923v.834h-.11c-.266-.595-.881-.964-1.6-.964-1.4 0-2.378 1.162-2.378 2.823 0 1.737.957 2.906 2.379 2.906.8 0 1.415-.39 1.709-1.087h.11c.081.67.703 1.148 1.503 1.148 1.572 0 2.57-1.415 2.57-3.643zm-7.177.704c0-1.197.54-1.907 1.456-1.907.93 0 1.524.738 1.524 1.907S8.308 9.84 7.371 9.84c-.895 0-1.442-.725-1.442-1.914z"></path>
    </svg>
      <input autocomplete="off" placeholder="Username" class="input-field" type="text"/>
    </div> */}
          <FormControl  variant="standard">
          <InputLabel style={{color:err=='err'?"red":""}} htmlFor="standard-adornment-password">password</InputLabel>
          <Input  id="standard-adornment-password" value={Userpassword} onChange={(e)=>{setUserpassword(e.target.value)}}  error={err=='err'?true:""}  className='textinput' label="Password to access course" type={showPassword?"text":"password"} variant="standard" 
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          />
          </FormControl>
          <FormControl fullWidth>
      </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{
            handleClose()
            setUseremail('')
            setUserpassword('')
            setBool(false)
            setErr("")
          }}>Cancel</Button>
          <Button onClick={()=>{
                
                users.map(data=>{
                  if(Useremail.toLowerCase()==data.email.toLowerCase() && Userpassword==data.password){
                    setUserRN(data)
                    console.log(data)
                    // checker(data.email)

                    axios.get("https://astucefx.herokuapp.com/ok/getcourse")
                    .then((e)=>{
                      setClasses(e.data.msg[0])
                    // console.log(e.data.msg[0])
                    // console.log(classes)

                    
                      
                    })
                    .catch(e=>{
                      console.log("error",e)
                    })



                    console.log(data.email)
                    data.pdf!=undefined?setUserpdf(data.pdf):setUserpdf(true)
                    // setUserpdf(data.pdf)
                    console.log(data.pdf)
                    if(data.Browser==false){
                      //warn user
                      setID(data.ID)
                      setOpenA(true)

                    }else{
                      AstuceFXUser.collection('user').get().then(user => {
                          if(user.length==0){
                            //cant login browser not good
                            setOpenB(true)
                          } else if(data.ID==user[0].ID){
                            setLink(data.ID)
                            setBool(true)
                            handleClose()
                          }else{
                            setOpenC(true)

                          }
                        })
                      
                    }
                    console.log('u are in')
                  }else{
                    setErr('err')
                  }

                })

          }} autoFocus>
            Log in
          </Button>



          
        </DialogActions>

{/* <div className="form">
    <p id="heading">Login</p>
    <div className="field">
    <svg className="input-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
    <path d="M13.106 7.222c0-2.967-2.249-5.032-5.482-5.032-3.35 0-5.646 2.318-5.646 5.702 0 3.493 2.235 5.708 5.762 5.708.862 0 1.689-.123 2.304-.335v-.862c-.43.199-1.354.328-2.29.328-2.926 0-4.813-1.88-4.813-4.798 0-2.844 1.921-4.881 4.594-4.881 2.735 0 4.608 1.688 4.608 4.156 0 1.682-.554 2.769-1.416 2.769-.492 0-.772-.28-.772-.76V5.206H8.923v.834h-.11c-.266-.595-.881-.964-1.6-.964-1.4 0-2.378 1.162-2.378 2.823 0 1.737.957 2.906 2.379 2.906.8 0 1.415-.39 1.709-1.087h.11c.081.67.703 1.148 1.503 1.148 1.572 0 2.57-1.415 2.57-3.643zm-7.177.704c0-1.197.54-1.907 1.456-1.907.93 0 1.524.738 1.524 1.907S8.308 9.84 7.371 9.84c-.895 0-1.442-.725-1.442-1.914z"></path>
    </svg>
      <input autocomplete="off" placeholder="Username" className="input-field" type="text"/>
    </div>
    <div className="field">
    <svg className="input-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
    <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"></path>
    </svg>
      <input placeholder="Password" className="input-field" type="password"/>
    </div>

    <button className="button2">Login</button>
</div> */}
      </Dialog>


      <Dialog
                open={openA}
                onClose={handleCloseA}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
        <DialogTitle id="alert-dialog-title">
          {"Warning"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {/* <TextField value={Useremail} onChange={(e)=>{setUseremail(e.target.value)}} error={err=='err'?true:""} helperText={userInfo.name==''?"enter your name":""}  className='textinput' label="Email" variant="standard" />
          <TextField value={Userpassword} onChange={(e)=>{setUserpassword(e.target.value)}}  error={err=='err'?true:""} helperText={userInfo.name==''?"enter your email":""} type='password' className='textinput' label="Password" variant="standard" />
          <FormControl fullWidth>
      </FormControl> */}
      if you login with this Browser, you can not login with the same account in any other Browser
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{
            handleCloseA()
            setBool(false)
            handleClose()
            setUseremail("")
            setUserpassword("")
            setErr("")

          }}>Disagree</Button>
          <Button onClick={()=>{
            const database =AstuceFXUser.collection('user').get()
            .then(data=>{
              console.log(data.length)
              if(data.length==0){
                  AstuceFXUser.collection('user').add({
                                  id: 1,
                                  email: Useremail,
                                  password: Userpassword,
                                  ID: ID,
                                })
                                .then(()=>{
                                  console.log('new collection created')
                                })
              }else{
                AstuceFXUser.collection('user')
                .doc({ id: 1 })
                .update({
                        email: Useremail,
                        password: Userpassword,
                        ID: ID,
                })
                .then(response => {
                  console.log('Update successful')
                })
                .catch(error => {
                  console.log('There was an error')
                })
                
              }
            })

                
              axios.post(urlModifyBrowser,{Browser:true})
              .then(data=>{
                console.log("successful")
              })
              .catch(err=>{
                console.log(err)
              })
            handleCloseA()
            setBool(true)
            handleClose()

          }}  autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>



      <Dialog
                open={openB}
                onClose={handleCloseB}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
        <DialogTitle id="alert-dialog-title">
          {"Warning"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {/* <TextField value={Useremail} onChange={(e)=>{setUseremail(e.target.value)}} error={err=='err'?true:""} helperText={userInfo.name==''?"enter your name":""}  className='textinput' label="Email" variant="standard" />
          <TextField value={Userpassword} onChange={(e)=>{setUserpassword(e.target.value)}}  error={err=='err'?true:""} helperText={userInfo.name==''?"enter your email":""} type='password' className='textinput' label="Password" variant="standard" />
          <FormControl fullWidth>
      </FormControl> */}
      you can't access the course with this Browser, Use the Browser you registered your account with 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{
            const id=genRand(20)
            console.log(id)
            
            const urlModifyID=`https://astucefx.herokuapp.com/ok/updateid/${Useremail}`
              AstuceFXUser.collection('user').add({
                id: 1,
                email: Useremail,
                password: Userpassword,
                ID: id,
              })
              .then(()=>{
                
                console.log('created successfully')
                axios.post(urlModifyID,{ID:id})
              .then(data=>{
                console.log("successful")
                setOpenB(false)
                setOpen(false)
                setBool(true)
                setUseremail('')
                setUserpassword("")
                setErr("")

              })
              .catch(err=>{
                console.log(err)
              })
            })
              .catch(err=>{console.log('err')})
         }}>Use this Browser</Button>
          <Button onClick={()=>{
            setOpenB(true)
            setBool(false)
            setOpen(true)
            setUseremail('')
            setUserpassword("")
            setErr("")

          }}  autoFocus>
            continue
          </Button>
        </DialogActions>
      </Dialog>




      <Dialog
                open={openC}
                onClose={handleCloseC}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
        <DialogTitle id="alert-dialog-title">
          {"Warning"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {/* <TextField value={Useremail} onChange={(e)=>{setUseremail(e.target.value)}} error={err=='err'?true:""} helperText={userInfo.name==''?"enter your name":""}  className='textinput' label="Email" variant="standard" />
          <TextField value={Userpassword} onChange={(e)=>{setUserpassword(e.target.value)}}  error={err=='err'?true:""} helperText={userInfo.name==''?"enter your email":""} type='password' className='textinput' label="Password" variant="standard" />
          <FormControl fullWidth>
      </FormControl> */}
      you can't access the course with this Browser, Use the Browser you registered your account with 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{
            const id=genRand(20)
            
            const urlModifyID=`https://astucefx.herokuapp.com/ok/updateid/${Useremail}`
              AstuceFXUser.collection('user')
              .doc({ id: 1 })
              .update({
                id: 1,
                email: Useremail,
                password: Userpassword,
                ID: id
              })
              .then(()=>{
                
                console.log('created successfully')
                setLink(id)
                axios.post(urlModifyID,{ID:id})
              .then(data=>{
                console.log("successful")
                setOpenC(false)
                setOpen(false)
                setBool(true)
                setUseremail('')
                setUserpassword("")
                setErr("")


              })
              .catch(err=>{
                console.log(err)
              })
            })
              .catch(err=>{console.log('err')})
         }}>Use this Browser</Button>
          <Button onClick={()=>{
            setOpenC(false)
            setBool(false)
            setOpen(false)
            setUseremail('')
            setUserpassword("")
            setErr("")

          }}  autoFocus>
            continue
          </Button>
        </DialogActions>
      </Dialog>

          
      <Dialog
                open={openY}
                onClose={handleCloseY}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
        <DialogTitle id="alert-dialog-title">
          {"List"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           {userRN.referral.map((fruit, index) => 
        index === 0 ? null : <div key={index}>{fruit}</div>
      )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{

         }}>DM Astucefx  to withdraw your commission</Button>
          <Button onClick={()=>{

            setOpenY(false)
          }}  autoFocus>
            okay
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
                open={openX}
                onClose={handleCloseX}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
        <DialogTitle id="alert-dialog-title">
          {"List"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            log out then login to get your referral link
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{

            setOpenX(false)
          }}  autoFocus>
            okay
          </Button>
        </DialogActions>
      </Dialog>



      <Dialog
                open={openQuizz}
                onClose={handleCloseQuizz}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
        <DialogTitle id="alert-dialog-title">
          {"Quizz"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Quizz to win 10K MFF
            <Quiz quiz={Quizz} showDefaultResult={true}  onComplete={(obj)=>{
              // console.log(obj)
              // console.log(userRN.email)
              // https://astucefx.herokuapp.com/ok/getcourse
              //https://astucefx.herokuapp.com/ok/getquizz
              // axios.get("https://astucefx.herokuapp.com/ok/getquizz")
              // .then(data=>{
              //   // console.log(data.data.msg[0].user)
              //   if(data.status===200){
              //     const email=userRN.email
              //     // const user=[...,data.data.msg.push({[email]:obj.numberOfCorrectAnswers})
              //     let participant =data.data.msg[0].user;
              //     const desiredElement = participant.find(obj => Object.keys(obj)[0].includes(email));

              //       if(desiredElement){
              //         alert("you already done the quizz, your result cant be submitted again😏😏");
              //       }else{
              //         participant.push({[email]:obj.numberOfCorrectAnswers})
              //         axios.post("https://astucefx.herokuapp.com/ok/updateQuizzUser",{user:participant})
              //         .then(data=>{
              //         if(data.status==200){
              //           alert('Welldone, u will have the result to your Quizz soon')
              //         }
              //       })
              //       }

              //     //participant.push({[email]:obj.numberOfCorrectAnswers})
              //     // data.data.msg.push({email:obj.numberOfCorrectAnswers})
              //     // axios.post("https://astucefx.herokuapp.com/ok/updateQuizzUser",{user:participant})
              //     // .then(data=>{
              //     //   if(data.status==200){
              //     //     alert('Weldone, u will have the result to your Quizz soon')
              //     //   }
              //     // })
              //   }
              // })
            }}/>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{

            setOpenQuizz(false)
          }}  autoFocus>
            okay
          </Button>
        </DialogActions>
      </Dialog>


        </div>
    )
}


