import React, { useState } from 'react';
import { Container, Typography, Box, Grid, IconButton, TextField, Button } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import axios from 'axios';
import { Snackbar, Alert } from '@mui/material';

function PaymentAndSocials() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('https://astucefx.herokuapp.com/api/sendmsg', { email: email, comment: message })
      .then(response => {
        if (response.data.status) {
          setSnackbarSeverity('success');
          setSnackbarMessage('Message sent successfully, Astucefx will reply to you soon.');
          setSnackbarOpen(true);
          setMessage('');
          setEmail('');
        }
      })
      .catch(err => {
        console.log(err);
        setSnackbarSeverity('error');
        setSnackbarMessage('Error sending message');
        setSnackbarOpen(true);
      });
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  return (
    <Box sx={{ backgroundColor: '#000', color: '#fff', padding: 4 }}>
      <Container>
        {/* Different Payment Section */}
        <Box sx={{ textAlign: 'center', marginBottom: 4 }}>
          <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold' }}>
            DIFFERENT PAYMENT
          </Typography>
          <Typography variant="body1">
            DM AstuceFx on WhatsApp or Instagram to proceed through crypto or Google Pay. If you have any issue paying through the website, contact us or scroll down.
          </Typography>
        </Box>

        <Box sx={{ borderBottom: 1, borderColor: '#d4af37', marginY: 4 }}></Box>

        {/* Contact Form */}
        <Box sx={{ textAlign: 'center', marginBottom: 4 }}>
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
            CONTACT US
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 1000, minWidth: 300, margin: 'auto' }}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                style: { color: 'white' },
              }}
              InputLabelProps={{
                style: { color: 'white' },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#d4af37',
                  },
                  '&:hover fieldset': {
                    borderColor: '#d4af37',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#d4af37',
                  },
                },
              }}
            />
            <TextField
              label="Message"
              variant="outlined"
              fullWidth
              margin="normal"
              multiline
              rows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              InputProps={{
                style: { color: 'white' },
              }}
              InputLabelProps={{
                style: { color: 'white' },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#d4af37',
                  },
                  '&:hover fieldset': {
                    borderColor: '#d4af37',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#d4af37',
                  },
                },
              }}
            />
            <Button
              type="submit"
              variant="outlined"
              sx={{
                marginTop: 2,
                borderColor: '#d4af37',
                color: '#d4af37',
                '&:hover': {
                  borderColor: '#d4af37',
                  backgroundColor: 'rgba(212, 175, 55, 0.1)',
                }
              }}
            >
              Send Message
            </Button>
          </Box>
        </Box>

        <Box sx={{ borderBottom: 1, borderColor: '#d4af37', marginY: 4 }}></Box>

        {/* Socials Section */}
        <Box sx={{ textAlign: 'center', marginBottom: 4 }}>
          <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold' }}>
            SOCIALS
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <IconButton component="a" href="https://www.instagram.com/yourpage" target="_blank" rel="noopener noreferrer">
                <InstagramIcon sx={{ fontSize: 40, color: '#fff' }} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton component="a" href="https://www.youtube.com/yourchannel" target="_blank" rel="noopener noreferrer">
                <YouTubeIcon sx={{ fontSize: 40, color: '#fff' }} />
              </IconButton>
            </Grid>

          </Grid>
        </Box>

        <Typography variant="body2" sx={{ textAlign: 'center', marginTop: 4 }}>
          Disclaimer: I am not a financial advisor. I am not a CPA or any legal investing advisor. None of the information on this website, in the course, or in the PDF is financial advice and it should not be treated as such. AstuceFx Day Trading Academy
        </Typography>
      </Container>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
export default PaymentAndSocials;
