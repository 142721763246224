import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, IconButton, Tooltip, Snackbar, Alert, TableSortLabel, Chip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {jwtDecode} from 'jwt-decode';
const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#000000',
      paper: '#121212',
    },
    primary: {
      main: '#d4af37',
    },
    text: {
      primary: '#ffffff',
    },
  },
});

// const initialReferrals = [
//   { id: 1, name: 'John Doe', date: '2024-07-01', commission: '$50', status: 'Pending' },
//   { id: 2, name: 'Jane Smith', date: '2024-07-05', commission: '$75', status: 'Paid' },
//   // Add more referral data here
// ];

const AffiliateComponent = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [referrals, setReferrals] = useState([]);
  const [sortDirection, setSortDirection] = useState('asc');
  const [affiliateCode,setaffiliateCode] = useState("");
  const handleCopyClick = () => {
    navigator.clipboard.writeText(affiliateCode);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSort = () => {
    const isAsc = sortDirection === 'asc';
    const sortedReferrals = [...referrals].sort((a, b) => {
      if (a.status < b.status) return isAsc ? -1 : 1;
      if (a.status > b.status) return isAsc ? 1 : -1;
      return 0;
    });
    setSortDirection(isAsc ? 'desc' : 'asc');
    setReferrals(sortedReferrals);
  };

  const handleRequestPayment = () => {
    const updatedReferrals = referrals.map((referral) =>
      referral.status === 'Pending' ? { ...referral, status: 'Payment Requested' } : referral
    );
    setReferrals(updatedReferrals);
    alert('Payment requested for all pending referrals.');
  };

  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          // Decode the token to verify it's valid
          const user = jwtDecode(token);
          console.log('User:', user);
          setaffiliateCode(user.referral.referralCode)
        } catch (error) {
          console.error('Invalid token:', error);
        }
      }
    };

    checkAuth();
  }, []);


  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Affiliate Program
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography variant="body1" sx={{ mr: 2 }}>
            Share your affiliate code:
          </Typography>
          <Typography variant="body2" sx={{ mr: 2, backgroundColor: '#121212', padding: '4px 8px', borderRadius: '4px', color: '#d4af37' }}>
            {affiliateCode}
          </Typography>
          <Tooltip title="Copy to clipboard">
            <IconButton onClick={handleCopyClick} color="primary">
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Typography variant="h6" gutterBottom>
          People you have referred:
        </Typography>
        <Button variant="contained" color="primary" onClick={handleRequestPayment} sx={{ mb: 2 }}>
          Request Payment for Pending
        </Button>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Date Referred</TableCell>
                <TableCell>Commission</TableCell>
                <TableCell>
                  <TableSortLabel active direction={sortDirection} onClick={handleSort}>
                    Status
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {referrals.map((referral) => (
                <TableRow key={referral.id}>
                  <TableCell>{referral.name}</TableCell>
                  <TableCell>{referral.date}</TableCell>
                  <TableCell>{referral.commission}</TableCell>
                  <TableCell>
                    <Chip
                      label={referral.status}
                      color={referral.status === 'Paid' ? 'success' : referral.status === 'Pending' ? 'warning' : 'primary'}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
            Affiliate code copied to clipboard!
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
};
export default AffiliateComponent;
