import React, { useEffect, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, CardActions, Button, AppBar, Toolbar, Avatar, CssBaseline, Box, Menu, MenuItem, IconButton, CircularProgress } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import PsychologyIcon from '@mui/icons-material/Psychology';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {jwtDecode} from 'jwt-decode';
import AffiliateComponent from './AffiliateComponent'
const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#000000',
      paper: '#121212',
    },
    primary: {
      main: '#ff0000',
    },
    secondary: {
      main: '#00ff00',
    },
    text: {
      primary: '#ffffff',
      secondary: '#aaaaaa',
    },
  },
});

const products = [
  {
    title: "Trading Platform",
    description: "Learn everything you need to know about trading platforms, including how to select the right one for your needs and how to set it up for successful trading.",
    icon: <DesktopWindowsIcon style={{ fontSize: 80, color: '#d4af37' }} />,
  },
  {
    title: "Scalping Mastery",
    description: "Master the art of scalping with our comprehensive strategy guide, designed to help you scalp successfully in the markets.",
    icon: <TrendingUpIcon style={{ fontSize: 80, color: '#d4af37' }} />,
  },
  {
    title: "Synthetic Trading",
    description: "Learn how to successfully trade synthetic indices with our comprehensive guide, designed to help you master the strategies.",
    icon: <SyncAltIcon style={{ fontSize: 80, color: '#d4af37' }} />,
  },
  {
    title: "Psychology",
    description: "Understand the crucial aspects of trading psychology and learn how to manage your trades effectively with our comprehensive guide.",
    icon: <PsychologyIcon style={{ fontSize: 80, color: '#d4af37' }} />,
  },
];

const MyProducts = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          // Decode the token to verify it's valid
          const user = jwtDecode(token);
          console.log('User:', user);
          setIsAuthenticated(true);
        } catch (error) {
          console.error('Invalid token:', error);
          setIsAuthenticated(false);
        }
      } else {
        setIsAuthenticated(false);
      }
      setLoading(false);
    };

    checkAuth();
  }, []);

  useEffect(() => {
    if (!loading && !isAuthenticated) {
      navigate('/corfguuidsvgfwefcyvtgwafdyuewscgdtyesfewgtwrv'); // Redirect to login page if not authenticated
    }
  }, [loading, isAuthenticated, navigate]);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('token'); // Clear token from local storage
    handleMenuClose();
    navigate('/corfguuidsvgfwefcyvtgwafdyuewscgdtyesfewgtwrv'); // Redirect to login page after logout
    console.log("Logged out");
  };

  const menuId = 'primary-search-account-menu';

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          backgroundColor: '#000000',
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="static" style={{ backgroundColor: '#121212' }}>
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            AstuceFx Academy
          </Typography>
          <Typography variant="body1" style={{ marginRight: 20 }}>
            Courses
          </Typography>
          <Typography variant="body1" style={{ marginRight: 20 }}>
            Reviews
          </Typography>
          <IconButton
            edge="end"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <AccountCircleIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>

      <Container>
        <Typography variant="h4" gutterBottom style={{ marginTop: 20 }}>
          My Products
        </Typography>
        <Grid container spacing={4}>
          {products.map((product, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Card sx={{ maxWidth: 345, backgroundColor: '#121212' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 140 }}>
                  {product.icon}
                </Box>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div" color="textPrimary">
                    {product.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {product.description}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button 
                    size="small" 
                    variant="outlined" 
                    onClick={() => navigate('/dashboard')}
                    sx={{
                      borderColor: '#d4af37',
                      color: '#d4af37',
                      '&:hover': {
                        borderColor: '#d4af37',
                        backgroundColor: 'rgba(212, 175, 55, 0.1)',
                      },
                    }}
                  >
                    View Product
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Container>
        <AffiliateComponent/>
      </Container>
    </ThemeProvider>
  );
};

export default MyProducts;
