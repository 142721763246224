import React from 'react';
import { IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/material/styles';

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  width: 60,
  height: 60,
  borderRadius: '50%',
  border: '2px solid white',
  color: 'gold',
  backgroundColor: 'black',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  margin:20
}));

const CircleButton = () => {
  return (
    <CustomIconButton>
      <KeyboardArrowDownIcon fontSize="large" />
    </CustomIconButton>
  );
};

export default CircleButton;
