import React from 'react';
import { Card, CardContent, Typography, Box, useMediaQuery, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useNavigate } from 'react-router-dom';
function CourseBox({ course }) {
  const isLargeScreen = useMediaQuery('(min-width:600px)');
  const navigate = useNavigate();
  const handleEnroll = () => {
    navigate('/checkout', { state: { course } });
  };
  return (
    <Card
      sx={{
        backgroundColor: '#1b1b1b',
        color: 'white',
        padding: 2,
        borderRadius: 2,
        maxWidth: isLargeScreen ? 600 : 345,
        margin: 'auto',
        textAlign: 'center',
        position: 'relative' // Added for label positioning
      }}
    >
      {course.label && (
        <Box
          sx={{
            position: 'absolute',
            top: -5,
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: course.label === 'POPULAR' ? '#ff9800' : '#4caf50',
            color: 'white',
            padding: '2px 8px',
            borderRadius: '4px',
            fontSize: '0.75rem',
            fontWeight: 'bold',
          }}
        >
          {course.label}
        </Box>
      )}
      <CardContent>
        <Typography variant="h6" gutterBottom sx={{ color: '#d4af37' }}>
          {course.title}
        </Typography>
        <Typography variant="h4" gutterBottom sx={{ color: '#d4af37' }}>
          ${course.price}
        </Typography>
        <Box sx={{ borderBottom: 1, borderColor: 'grey.500', marginBottom: 2 }}></Box>
        {course.features.map((feature, index) => (
          <Box key={index} display="flex" alignItems="center" justifyContent="flex-start" mb={1}>
            {feature.available ? (
              <CheckCircleOutlineIcon sx={{ color: '#d4af37', marginRight: 1 }} />
            ) : (
              <CancelOutlinedIcon sx={{ color: 'grey', marginRight: 1 }} />
            )}
            <Typography variant="body1">{feature.name}</Typography>
          </Box>
        ))}
       <Button 
          variant="outlined" 
          sx={{ 
            marginTop: 2, 
            borderColor: '#d4af37', 
            color: '#d4af37',
            '&:hover': {
              borderColor: '#d4af37',
              backgroundColor: 'rgba(212, 175, 55, 0.1)',
            }
          }}
          onClick={handleEnroll}
        >
          Enroll
        </Button>
      </CardContent>
    </Card>
  );
}
export default CourseBox;