import React from 'react';
import { Box, Typography, Container, Grid } from '@mui/material';

function InfoBox({ icon, title, description }) {
    return (
        <Box
            sx={{
                backgroundColor: '#1b1b1b',
                color: 'white',
                padding: 3,
                borderRadius: 2,
                textAlign: 'center',
                transition: 'background-color 1s ease',
                width: '300px',
                height: '350px',
            }}
        >
            <div style={{
                display:"flex",
                flexDirection:"column",
                gap:0
            }}>
                <Box style={{
                    height:100,
                    width:100
                }}>{icon}</Box>
                <Typography variant="h6" component="div" gutterBottom>
                    {title}
                </Typography>
            </div>

            <Typography className='description' variant="body" component="p">
                {description}
            </Typography>
        </Box>
    );
}
export default InfoBox;