import React, { useState, useEffect } from 'react';
import logo from '../img/logo-nobg.png'
import { useLocation } from 'react-router-dom';
import { Container, Typography, Box, TextField, Button, Grid, RadioGroup, FormControlLabel, Radio, FormControl, FormLabel } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
function CheckoutPage() {
  const location = useLocation();
  const { course } = location.state || {};
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('card');


  console.log(course.title)
  const config = {
    public_key: 'FLWPUBK-f0b5c6e68e8ba8fca3e36f0b259b0004-X',
    tx_ref: Date.now(),
    amount: course.price,
    currency: 'USD',
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: email,
      name: fullName,
    },
    customizations: {
      title: course.title,
      logo: logo,
    },
  };
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  const handleFlutterPayment = useFlutterwave(config);
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    if (fullName === "" || email === "" || password === "" || confirmPassword !== password) {
      alert("Please fill out all fields correctly.");
      return;
    }
    if (paymentMethod === 'card') {
      handleFlutterPayment({
        callback: (response) => {
          console.log(response);
          closePaymentModal()
        },
        onClose: () => { },
      });
    } else {

    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <Box sx={{ backgroundColor: '#000', color: '#fff', padding: 4, minHeight: '100vh' }}>
      <Container sx={{ maxWidth: 600, margin: 'auto' }}>
        <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
          Checkout
        </Typography>
        {course && (
          <Box sx={{ marginBottom: 4 }}>
            <Typography variant="h5" gutterBottom sx={{ textAlign: 'center' }}>
              {course.title}
            </Typography>
            <Typography variant="h6" gutterBottom sx={{ textAlign: 'center', color: '#d4af37' }}>
              ${course.price}
            </Typography>
            <Box sx={{ borderBottom: 1, borderColor: 'grey.500', marginY: 2 }}></Box>
            {course.features.map((feature, index) => (
              <Box key={index} display="flex" alignItems="center" justifyContent="flex-start" mb={1}>
                {feature.available ? (
                  <CheckCircleOutlineIcon sx={{ color: '#d4af37', marginRight: 1 }} />
                ) : (
                  <CancelOutlinedIcon sx={{ color: 'grey', marginRight: 1 }} />
                )}
                <Typography variant="body1">{feature.name}</Typography>
              </Box>
            ))}
          </Box>
        )}
        <form onSubmit={handleSubmit}>
          <TextField
            label="Full Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            InputProps={{
              style: { color: 'white' },
            }}
            InputLabelProps={{
              style: { color: 'white' },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#d4af37',
                },
                '&:hover fieldset': {
                  borderColor: '#d4af37',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#d4af37',
                },
              },
            }}
          />
          <TextField
            label="Email Address"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputProps={{
              style: { color: 'white' },
            }}
            InputLabelProps={{
              style: { color: 'white' },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#d4af37',
                },
                '&:hover fieldset': {
                  borderColor: '#d4af37',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#d4af37',
                },
              },
            }}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              style: { color: 'white' },
            }}
            InputLabelProps={{
              style: { color: 'white' },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#d4af37',
                },
                '&:hover fieldset': {
                  borderColor: '#d4af37',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#d4af37',
                },
              },
            }}
          />
          <TextField
            label="Confirm Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            InputProps={{
              style: { color: 'white' },
            }}
            InputLabelProps={{
              style: { color: 'white' },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#d4af37',
                },
                '&:hover fieldset': {
                  borderColor: '#d4af37',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#d4af37',
                },
              },
            }}
          />
          <FormControl component="fieldset" sx={{ marginY: 2 }}>
            <FormLabel component="legend" sx={{ color: 'white' }}>Payment Method</FormLabel>
            <RadioGroup
              row
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
              sx={{ justifyContent: 'center' }}
            >
              <FormControlLabel value="card" control={<Radio sx={{ color: '#d4af37' }} />} label="Card" />
              <FormControlLabel value="crypto" control={<Radio sx={{ color: '#d4af37' }} />} label="Crypto" />
            </RadioGroup>
          </FormControl>
          <Button
            type="submit"
            variant="outlined"
            fullWidth
            sx={{
              marginTop: 2,
              borderColor: '#d4af37',
              color: '#d4af37',
              '&:hover': {
                borderColor: '#d4af37',
                backgroundColor: 'rgba(212, 175, 55, 0.1)',
              }
            }}
          >
            Submit Payment
          </Button>
        </form>
      </Container>
    </Box>
  );
}

export default CheckoutPage;
