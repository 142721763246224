import './App.css';
import {BrowserRouter,Route, Routes} from 'react-router-dom'
import React from 'react';
import Home from './Components/homepage/Home';
import CheckoutPage from './Components/homepage/CheckoutPage';
import {Courses} from './Components/Courses/Courses'
import Login from './Components/Courses/Login'
import MyProducts from './Components/Courses/MyProducts'
import Dashboard from './Components/Courses/Dashboard'
import AdminDashboard from './Components/Courses/AdminDashboard'
import { useEffect } from 'react';
function App() {


  return (
    <BrowserRouter>
      <Routes>
        <Route path='/*' element={<Home/>}/>
        <Route path='/checkout' element={<CheckoutPage/>}/>
        {/* <Route path='/corfguuidsvgfwefcyvtgwafdyuewscgdtyesfewgtwrv' element={<Courses/>}/> */}
        <Route path='/corfguuidsvgfwefcyvtgwafdyuewscgdtyesfewgtwrv' element={<Login/>}/>
        <Route path='/MyProducts' element={<MyProducts/>}/>
        <Route path='/Dashboard' element={<Dashboard/>}/>
        <Route path='/AdminDashboard' element={<AdminDashboard/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;